import { get } from 'lodash'

import * as AccountMutations from '@/graphql/account.js'

import store from '@/utils/store'
import { createProvider } from '@/utils/vue-apollo'

const apollo = createProvider().defaultClient

const profile = async sectionInfo => {
  const { id } = store.state.member
  const {
    data: { updateMemberSubset },
  } = await apollo.mutate({
    mutation: AccountMutations.UPDATE_MEMBER,
    variables: {
      input: {
        birthday: get(sectionInfo, 'birthdate'),
        displayName: get(sectionInfo, 'displayName'),
        id,
        legalLocaleId: get(sectionInfo, 'legalLocaleId'),
        name,
        timezoneId: get(sectionInfo, 'timezoneId'),
      },
    },
  })

  store.commit('setMember', updateMemberSubset)
}

const username = async sectionInfo => {
  const { email } = sectionInfo
  const {
    data: {
      setMemberEmail: { member },
    },
  } = await apollo.mutate({
    mutation: AccountMutations.UPDATE_USERNAME,
    variables: { input: { email } },
  })

  store.commit('setMember', member)
}

const emailForAccount = async sectionInfo => {
  const emailId = store.state.member.contacts[0].emails[0].id
  const { email, contactId } = sectionInfo
  const {
    data: { contactsUpsertEmail },
  } = await apollo.mutate({
    mutation: AccountMutations.UPDATE_EMAIL,
    variables: { input: { email, contactId, id: emailId } },
  })
  const identityId = store.state.principal.identityId // eslint-disable-line
  await apollo.mutate({
    mutation: AccountMutations.UPDATE_USERNAME,
    variables: {
      input: {
        identityId,
        username: emailForAccount,
      },
    },
  })
  store.commit('setContactEmail', contactsUpsertEmail.email)
}

// const phoneNumber = sectionInfo => {
//   const { id, phoneNumber: number, contactId } = sectionInfo
//   return apollo.mutate({
//     mutation: AccountMutations.UPDATE_PHONE,
//     variables: { input:
//       {
//         id,
//         number,
//         contactId: contactId,
//       },
//     },
//   })
// }

const address = sectionInfo => {
  return apollo.mutate({
    mutation: AccountMutations.UPDATE_ADDRESS,
    variables: {
      input: {
        city: get(sectionInfo, 'city'),
        country: get(sectionInfo, 'country'),
        id: get(sectionInfo, 'id'),
        memberId: get(sectionInfo, 'memberId'),
        name: get(sectionInfo, 'name'),
        postalCode: get(sectionInfo, 'postalCode'),
        province: get(sectionInfo, 'province'),
        street: get(sectionInfo, 'street'),
        street2: get(sectionInfo, 'street2'),
        type: get(sectionInfo, 'type'),
      },
    },
  })
}

const password = async sectionInfo => {
  const { newPassword, oldPassword, email } = sectionInfo
  const {
    data: { changePassword },
  } = await apollo.mutate({
    mutation: AccountMutations.UPDATE_PASSWORD,
    variables: { changeInput: { newPassword, oldPassword, email } },
  })
  if (!changePassword.success) {
    return Promise.reject(new Error())
  } else {
    store.commit('setJwt', changePassword.accesstoken)
  }
}

const userInfo = async sectionInfo => {
  const {
    accesstoken,
    firstName,
    lastName,
    phoneNumber,
    email,
    phoneCode,
  } = sectionInfo
  const {
    data: { updateUser },
  } = await apollo.mutate({
    mutation: AccountMutations.UPDATE_USER,
    variables: {
      userData: {
        accesstoken,
        firstName,
        lastName,
        phoneNumber,
        email,
        phoneCode,
      },
    },
  })
  if (!updateUser.success) {
    return Promise.reject(new Error())
  } else {
    store.commit('setUser', {
      ...store.getters['user'],
      firstName,
      lastName,
      phoneNumber,
      phoneCode,
    })
  }
}

const link = sectionInfo => {
  const { slug } = sectionInfo
  return apollo.mutate({
    mutation: AccountMutations.SLUG,
    variables: { input: { slug } },
  })
}

export const preferences = sectionInfo => {
  return apollo.mutate({
    mutation: AccountMutations.SET_CONTACT_METHOD,
    variables: {
      input: { ...sectionInfo },
    },
  })
}

export const profileMap = {
  address,
  password,
  userInfo,
  // phoneNumber,
  profile,
  // email,
  username,
  preferences,
  link,
}

export const upsertStripeIntegration = code => {
  return apollo
    .mutate({
      mutation: AccountMutations.UPSERT_STRIPE_INTEGRATION,
      variables: { input: { code } },
    })
    .then(({ data }) => get(data, 'addStripeConnectIntegration'))
}

export const getStripeConnectLink = () => {
  return apollo
    .query({
      query: AccountMutations.GET_STRIPE_CONNECT_LINK,
      variables: { memberId: store.state.member.id },
    })
    .then(({ data }) => get(data, 'stripeConnectLinkByMember'))
}
