<template>
  <v-card flat tile color="grey" class="pt-120">
    <v-row no-gutters align="center" justify="center">
      <v-flex v-if="!userInfo.verifiedEmail" xs12>
        <v-sheet
          class="text-center py-2 text-subtitle-2 font-weight-bold"
          color="blue_spindle"
          height="30"
        >
          {{ $t('email_confirmation_text') }}
          <a
            class="black--text text-decoration-underline"
            @click.prevent="resendEmailActivate()"
          >
            {{ $t('email_resend_confirmation') }}
          </a>
        </v-sheet>
      </v-flex>
      <v-flex xs12 md6 lg4 xl3 sm12 text-center pt-10>
        <!-- <Claimed></Claimed> -->

        <v-card-text class="text-left text-h3">
          {{ $t('account_settings') }}
        </v-card-text>

        <v-card flat rounded color="white" class="pa-8">
          <v-form ref="userInfo" lazy-validation>
            <v-row no-gutters align="center" justify="center">
              <v-flex xs12>
                <AlertBox
                  v-if="userInfo.error"
                  :message="userInfo.error"
                  classes="mb-3"
                />
                <AlertBox
                  v-if="userInfo.success"
                  :is-error="false"
                  :message="userInfo.success"
                  classes="mb-3"
                />
              </v-flex>
              <v-flex xs12 sm12 mb-6 v-if="!this.$store.state.user.istalent">
                <v-btn
                  x-large
                  block
                  elevation="0"
                  color="primary"
                  dark
                  class="white--text"
                  height="65"
                  :loading="userInfo.loading"
                  @click="$router.push({ path: '/signup', query: { tabs: 6 } })"
                >
                  {{ $t('become_host') }}
                </v-btn>
              </v-flex>
              <!-- <v-flex xs12 sm12 mb-4>
                <v-row no-gutters align="center">
                  <v-card flat xs4 align-self-center rounded-card>
                    <v-img
                      height="96px"
                      width="96px"
                      :src="profileImage"
                      alt="avatar"
                    />
                  </v-card>
                  <v-flex xs6 md6 text-left ml-8>
                    <input
                      ref="file"
                      hidden
                      type="file"
                      accept="image/*"
                      @change="updateProfileImage()"
                    />
                    <v-btn
                      elevation="0"
                      large
                      block
                      outlined
                      :loading="loading"
                      @click="$refs.file.click()"
                    >
                      {{ $t('upload_image') }}
                    </v-btn>
                  </v-flex>
                </v-row>
              </v-flex> -->
              <v-flex xs12 sm12 mb-4>
                <v-row no-gutters>
                  <v-flex xs12 sm6 pr-5>
                    <v-text-field
                      v-model="userInfo.firstName"
                      :rules="rules.name"
                      maxlength="150"
                      class="font-weight-medium"
                      :placeholder="$t('first_name')"
                      outlined
                      required
                    />
                  </v-flex>
                  <v-flex xs12 sm6 pl-5>
                    <v-text-field
                      v-model="userInfo.lastName"
                      :rules="rules.name"
                      maxlength="150"
                      class="font-weight-medium"
                      :placeholder="$t('last_name')"
                      outlined
                      required
                    />
                  </v-flex>
                  <v-flex xs12 mt-n2>
                    <v-text-field
                      v-model="userInfo.email"
                      :rules="rules.email"
                      class="font-weight-medium"
                      :placeholder="$t('email_address')"
                      outlined
                      required
                      readonly
                      type="email"
                    />
                  </v-flex>
                  <v-flex xs12 mt-n2 mb-4 class="phonenum">
                    <VuePhoneNumberInput
                      id="phoneNumber"
                      v-model="phoneNumber"
                      :default-country-code="phoneCode"
                      :required="true"
                      color="#F7F8FB"
                      @update="onUpdate"
                    />
                    <div
                      v-if="phoneNumberError"
                      class="v-text-field__details pt-6"
                    >
                      <div
                        class="v-messages theme--light error--text"
                        role="alert"
                      >
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message">
                            {{ phoneNumberError }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-flex>
                  <!-- <v-flex
                    xs12
                    sm6
                    pr-5
                    my-4
                  >
                    <v-text-field
                      v-model="userInfo.zipcode"
                      :rules="rules.zipCode"
                      class="font-weight-medium"
                      :placeholder="$t('zip_code')"
                      outlined
                      required
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    pl-5
                    my-4
                  >
                    <v-text-field
                      v-model.trim="userInfo.city"
                      :rules="rules.city_name"
                      class="font-weight-medium"
                      :placeholder="$t('city')"
                      outlined
                      required
                    />
                  </v-flex> -->
                </v-row>
              </v-flex>
              <v-flex xs12 sm12 mb-3>
                <v-btn
                  x-large
                  block
                  elevation="0"
                  color="primary"
                  dark
                  class="white--text"
                  height="65"
                  :loading="userInfo.loading"
                  @click.prevent="update('userInfo')"
                >
                  {{ $t('save_update') }}
                </v-btn>
              </v-flex>
            </v-row>
          </v-form>
        </v-card>
        <v-card flat rounded color="white" class="pa-8 my-5">
          <v-form ref="password" lazy-validation>
            <v-row no-gutters align="center" justify="center">
              <AlertBox
                v-if="password.error"
                :message="password.error"
                classes="mb-3"
              />
              <AlertBox
                v-if="password.success"
                :message="password.success"
                :isError="false"
                color="light-green lighten-3"
                classes="mb-3"
              />
              <v-flex xs12 sm12>
                <v-text-field
                  v-model="password.oldPassword"
                  :rules="rules.required"
                  :placeholder="$t('current_pwd')"
                  outlined
                  required
                  type="password"
                />
              </v-flex>
              <v-flex xs12 sm12 mt-n2>
                <v-text-field
                  v-model="password.newPassword"
                  :rules="rules.password"
                  :placeholder="$t('new_pwd')"
                  outlined
                  required
                  type="password"
                />
              </v-flex>
              <v-flex xs12 sm12 mt-n2>
                <v-btn
                  large
                  block
                  color="primary"
                  dark
                  elevation="0"
                  class="white--text"
                  height="65"
                  :loading="password.loading"
                  @click.prevent="update('password')"
                >
                  {{ $t('change_password') }}
                </v-btn>
              </v-flex>
            </v-row>
          </v-form>
        </v-card>
        <!-- START: Stripe connect button -->
        <!-- <v-card
          v-if="userInfo.istalent"
          flat
          rounded
          color="white"
          class="pa-8 my-5"
        >
          <v-card-title class="text-center text-h3 mb-5">
            {{ $t('connect_stripe') }}
          </v-card-title>
          <v-row no-gutters align="center" justify="center">
            <AlertBox
              v-if="stripeError"
              :message="stripeError"
              classes="mb-3"
            />

            <v-flex xs12 sm12 mt-n2>
              <v-btn
                large
                block
                color="primary"
                elevation="0"
                class="white--text no-shadow"
                height="65"
                :loading="loadingStripe"
                :href="stripeUrl"
                target="_blank"
                :disabled="userInfo.payoutEnabled"
              >
                {{ $t('add_stripe_details') }}
              </v-btn>
            </v-flex>
          </v-row>
        </v-card> -->
        <!-- END: stripe connect button -->
      </v-flex>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'

import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// graphqlS
import { UPDATE_PROFILE_IMG, GET_STRIPE_ACCOUNT } from '@/graphql/account'
import { RESEND_EMAIL_ACTIVATION } from '@/graphql/authentication'

// utils
import { rules } from '@/utils/validation.js'
import { profileMap } from '@/utils/account'
import { delay } from '@/utils/helpers'
import i18n from '@/utils/i18n.js'
import { countryCode } from '@/utils/static'

export default {
  name: 'AccountSettings',
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      rules,
      loading: false,
      stripeError: null,
      loadingStripe: false,
      stripeUrl: null,
      phoneNumberError: null,
      phoneNumber: null,
      phoneCode: 'US',
      userInfo: {
        loading: false,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        phoneCode: null,
        // zipcode: null,
        // city:null,
        accesstoken: null,
        success: null,
        error: null,
      },
      password: {
        loading: false,
        oldPassword: null,
        newPassword: null,
        email: null,
        success: null,
        error: null,
      },
    }
  },
  computed: {
    ...mapGetters(['profileImage', 'user', 'jwt']),
  },
  async created() {
    await this.$store.dispatch('getUser')
    this.userInfo = { ...this.userInfo, ...this.user }
    this.userInfo.accesstoken = this.jwt
    this.password.email = this.userInfo.email
    this.phoneNumber = this.userInfo.phoneNumber

    if (this.userInfo.istalent) {
      this.getStripeAccount()
    }

    if (this.userInfo.phoneCode) {
      this.phoneCode = countryCode[this.userInfo.phoneCode]
    }
  },

  methods: {
    /**
     * get stripe url
     */
    async getStripeAccount() {
      this.loadingStripe = true
      try {
        const {
          data: { getStripeAccountUrl },
        } = await this.$apollo.mutate({
          mutation: GET_STRIPE_ACCOUNT,
          variables: {
            stripeData: {
              accesstoken: this.userInfo.accesstoken,
              email: this.userInfo.email,
            },
          },
        })

        // Set User ProfileImage in vuex
        if (getStripeAccountUrl.success) {
          this.stripeUrl = getStripeAccountUrl.url
        } else {
          this.stripeError = this.$t(getStripeAccountUrl.error)
        }
        this.loadingStripe = false
      } catch (e) {
        this.stripeError = e
        this.loadingStripe = false
        console.error(e) // eslint-disable-line
      }
    },
    /**
     * Update User Details
     */
    async update(section) {
      if (section === 'userInfo' && this.phoneNumberError != null) {
        return
      }
      if (!this.phoneNumber) {
        this.phoneNumberError = i18n.t('field_err_txt')
        return
      }

      if (!this.$refs[section].validate()) {
        this[section].error = 'Fix the below errors to update your Info'
        await delay(2000)
        this[section].error = null
        return
      }
      try {
        this[section].loading = true
        await profileMap[section]({
          ...this[section],
        })
        // Reset form if it's password section
        if (section === 'password') {
          this.$refs[section].reset()
        }
        this[section].loading = false
        this[section].success = 'Account details updated successfully.'
        await delay(3000)
        this[section].success = null
      } catch (e) {
        console.error(e) // eslint-disable-line
        this[section].loading = false
        this[section].error = 'Account details update Failed'
        await delay(3000)
        this[section].error = null
      }
    },

    /**
     * Update User Profile Image
     */
    async updateProfileImage() {
      this.userInfo.error = null
      this.profilePic = this.$refs.file.files[0]
      if (this.profilePic && /\.(jpe?g|png)$/i.test(this.profilePic.name)) {
        if (this.profilePic.size > 2000000) {
          this.$refs.file.value = ''
          this.userInfo.error = i18n.t('file_size_msg')
          return
        }
        /*
          Set the local file variable to what the user has selected.
        */
        this.loading = true
        try {
          const {
            data: { updateProfileImage },
          } = await this.$apollo.mutate({
            mutation: UPDATE_PROFILE_IMG,
            variables: {
              imageFile: this.profilePic,
              tokenData: {
                accesstoken: this.userInfo.accesstoken,
                email: this.userInfo.email,
              },
            },
          })

          // Set User ProfileImage in vuex
          if (updateProfileImage.success) {
            await this.$store.commit('setprofileImage', updateProfileImage.url)
            this.loading = false
          } else {
            this.error = this.$t(updateProfileImage.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.$refs.file.value = ''
        this.userInfo.error = i18n.t('invalid_file_type')
        return
      }
    },

    /**
     * Resend Email Activation
     */
    async resendEmailActivate() {
      try {
        const {
          data: { resendEmailActivate },
        } = await this.$apollo.mutate({
          mutation: RESEND_EMAIL_ACTIVATION,
          variables: {
            emailInput: {
              email: this.userInfo.email,
            },
          },
        })

        this.$toasted.options.position = 'bottom-right'
        if (resendEmailActivate.success) {
          this.$toasted.success(i18n.t('resend_email_txt'))
        } else {
          this.$toasted.error(this.$t(resendEmailActivate.error))
        }
      } catch (error) {
        this.$toasted.error(error)
      }
    },

    /**
     * Validate Phone Number
     */
    onUpdate(payload) {
      this.phoneNumberError = null
      if (payload.isValid) {
        this.phoneNumberError = null
        this.userInfo.phoneNumber = payload.nationalNumber
        this.userInfo.phoneCode = payload.countryCallingCode
      } else {
        this.phoneNumberError =
          this.userInfo.phoneNumber == null
            ? i18n.t('field_err_txt')
            : i18n.t('phone_txt')
      }
    },
  },
}
</script>

<style scoped>
.section {
  margin: 15px 0px;
}
.phonenum >>> .country-selector__list {
  top: 150% !important;
}
</style>
